import {Component} from '@angular/core';

@Component({
    selector: 'unauthorized-component',
    templateUrl: 'unauthorized.component.html',
    styleUrls: ['unauthorized.component.scss'],
    standalone: false
})

export class UnauthorizedComponent {
}
